exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-careers-contentful-jobs-slug-js": () => import("./../../../src/pages/AboutUs/Careers/{ContentfulJobs.slug}.js" /* webpackChunkName: "component---src-pages-about-us-careers-contentful-jobs-slug-js" */),
  "component---src-pages-about-us-careers-index-js": () => import("./../../../src/pages/AboutUs/Careers/index.js" /* webpackChunkName: "component---src-pages-about-us-careers-index-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-our-client-js": () => import("./../../../src/pages/AboutUs/OurClient.js" /* webpackChunkName: "component---src-pages-about-us-our-client-js" */),
  "component---src-pages-about-us-our-experts-and-team-js": () => import("./../../../src/pages/AboutUs/OurExpertsAndTeam.js" /* webpackChunkName: "component---src-pages-about-us-our-experts-and-team-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-experiences-babe-studies-js": () => import("./../../../src/pages/Experiences/BABEStudies.js" /* webpackChunkName: "component---src-pages-experiences-babe-studies-js" */),
  "component---src-pages-experiences-clinical-trials-js": () => import("./../../../src/pages/Experiences/ClinicalTrials.js" /* webpackChunkName: "component---src-pages-experiences-clinical-trials-js" */),
  "component---src-pages-experiences-contract-analysis-js": () => import("./../../../src/pages/Experiences/ContractAnalysis.js" /* webpackChunkName: "component---src-pages-experiences-contract-analysis-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-publication-js": () => import("./../../../src/pages/Gallery/Publication.js" /* webpackChunkName: "component---src-pages-gallery-publication-js" */),
  "component---src-pages-help-center-tracking-js": () => import("./../../../src/pages/HelpCenter/Tracking.js" /* webpackChunkName: "component---src-pages-help-center-tracking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-3-js": () => import("./../../../src/pages/News3.js" /* webpackChunkName: "component---src-pages-news-3-js" */),
  "component---src-pages-news-contentful-news-events-slug-js": () => import("./../../../src/pages/News/{ContentfulNewsEvents.slug}.js" /* webpackChunkName: "component---src-pages-news-contentful-news-events-slug-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/NewsDetail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-pharma-metric-labs-new-milestone-js": () => import("./../../../src/pages/News/PharmaMetricLabsNewMilestone.js" /* webpackChunkName: "component---src-pages-news-pharma-metric-labs-new-milestone-js" */),
  "component---src-pages-search-bar-js": () => import("./../../../src/pages/SearchBar.js" /* webpackChunkName: "component---src-pages-search-bar-js" */),
  "component---src-pages-services-babe-studies-js": () => import("./../../../src/pages/Services/BABEStudies.js" /* webpackChunkName: "component---src-pages-services-babe-studies-js" */),
  "component---src-pages-services-clinical-trials-js": () => import("./../../../src/pages/Services/ClinicalTrials.js" /* webpackChunkName: "component---src-pages-services-clinical-trials-js" */),
  "component---src-pages-services-contract-analysis-js": () => import("./../../../src/pages/Services/ContractAnalysis.js" /* webpackChunkName: "component---src-pages-services-contract-analysis-js" */),
  "component---src-pages-services-others-js": () => import("./../../../src/pages/Services/Others.js" /* webpackChunkName: "component---src-pages-services-others-js" */)
}

